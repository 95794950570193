import asyncFactory from '@/client/extensions/modules/asyncComponentFactory.js'


export default [
    {
        path: '/admin',
        component: asyncFactory('views/admin/Dashboard.vue'),
        name: 'admin-dashboard',
        meta : {
            layout : 'adminDashboard',
            requiresAdmin : true
        },
    },
    {
        path: '/admin/language',
        component: asyncFactory('views/admin/Language.vue'),
        name: 'admin-language',
        meta : {
            layout : 'adminDashboard',
            requiresAdmin : true
        },
    },

];
